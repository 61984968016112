import * as React from "react";
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ErrorPage, { Messages } from "../layouts/ErrorPage";

const NotFoundPage = () => {
  const {t} = useTranslation();

  const messages: Messages = {
    title: t('404.title'),
    subTitle: t('404.subTitle'),
    description: t('404.description'),
    buttonText: t('404.buttonText')
  };

  return (
    <ErrorPage messages={messages}/>
  )
};

export const pageQuery = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}`;

export default NotFoundPage;
